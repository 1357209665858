import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/linearb-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import PatternsIcon from '../../assets/images/product/blurb-icons/anti-patterns.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import SlideAndDiceIcon from '../../assets/images/product/blurb-icons/slide-and-dice.png'
import TakeActionIcon from '../../assets/images/product/blurb-icons/take-action.png'
import ZoomIcon from '../../assets/images/product/blurb-icons/zoom.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/investment-balance-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/enterprise/issue-pop-up.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/org-insights-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const SwarmiaVsLinearB = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="LinearB vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between LinearB and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>LinearB vs. Swarmia</>}
        content="LinearB does a stellar job in presenting a large number of engineering metrics. Swarmia, on the other hand, focuses on&nbsp;healthy, real-time metrics that drive the outcomes you&#39;re&nbsp;after."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="What do Swarmia and LinearB have in common?"
        content="Both Swarmia and LinearB surface useful engineering metrics and help developers stay in the flow with a well thought-out Slack integration."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Monitor engineering metrics"
            text="Track essential engineering metrics from DORA to investment balance."
            icon={PatternsIcon}
          />,
          <Blurb
            key="2"
            title="Software capitalization"
            text="Create audit-ready cost capitalization reports in minutes."
            icon={ZoomIcon}
          />,
          <Blurb
            key="3"
            title="GitHub Slack integration"
            text="Speed up developer workflows, automate feedback loops, and improve collaboration."
            icon={AllWorkIcon}
          />,
        ]}
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Why modern engineering organizations choose Swarmia over LinearB"
        content="Where LinearB surfaces a wide variety of engineering metrics, Swarmia only shows carefully selected, research-backed metrics that help you drive action."
      />
      <MosaicBlock
        title="No toxic metrics"
        content={
          <>
            Like many analytics-heavy engineering productivity tools, LinearB’s
            aim seems to be to surface as many metrics as humanly possible. The
            problem is, some of these metrics are nonsensical, while others are
            straight-up toxic.
            <br />
            <br />
            In contrast, we’re extremely selective about what data we surface in
            Swarmia. We only show measures that have a proven correlation with
            business performance, focusing on healthy team-level metrics that
            don’t make your engineers feel like they’re being spied on or
            unfairly compared.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Real-time, reliable data"
        content={
          <>
            A recurring piece of feedback we hear from former LinearB users is
            that they couldn’t trust the data because it was unclear how fresh
            it was and where it was coming from.
            <br />
            <br />
            Unlike LinearB, Swarmia shows data in real time, and you can drill
            down into any aggregate metric. This way, you can always trust the
            data and take concrete action based on the insights.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="A solution for the entire organization"
        content={
          <>
            Based on user feedback, LinearB struggles to provide value for the
            whole engineering organization. In comparison, Swarmia has been
            built from the bottom-up, providing value to everyone, from
            individual contributors to the C-level.
            <br />
            <br />
            Whether you’re a developer, engineering leader — or anything in
            between — Swarmia gives you the tools you need to succeed.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="alex2" />
      <LeadBlock
        heading="Works for your developers, not against them"
        content="While LinearB continues to provide toxic metrics, Swarmia is committed to advancing a healthy working environment for developers."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From developers to engineering leaders, Swarmia fits the needs of every level of your organization."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
          />,
          <Blurb
            key="2"
            title="Slice and dice the data"
            text="Drill into metrics behind the aggregates to understand what’s really going on."
            icon={SlideAndDiceIcon}
          />,
          <Blurb
            key="3"
            title="Take action"
            text="Use data to start conversations that drive the teams to improve."
            icon={TakeActionIcon}
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Join the high-performing teams using Swarmia today."
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/bringing-visibility-into-a-developers-work/',
            '/blog/github-slack-integration/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SwarmiaVsLinearB
